import {
    ApplierSubscription,
    CategoryNatureDetails,
    Client,
    ClientBase,
    ContactAccessesResponse,
    ContractClient,
    ContractDetails,
    ContractFull,
    LoggedUser,
    Package,
    Relationship,
    SubscriptionSet,
} from './ResearchContractApiClient';
import { ObjectsWithoutIdProperty, ObjectsWithoutIdPropertyEnum } from './ResearchContractApiSchema';

export class BaseResearchContractApiSchema {
    protected static getId(value: ObjectsWithoutIdProperty, type: ObjectsWithoutIdPropertyEnum): string {
        switch (type) {
            case ObjectsWithoutIdPropertyEnum.ApplierSubscription:
                return (value as ApplierSubscription).operationalContactId;

            case ObjectsWithoutIdPropertyEnum.CategoryNatureDetails:
                return (value as CategoryNatureDetails).natureId.toString();

            case ObjectsWithoutIdPropertyEnum.Client:
                return (value as Client).icId;

            case ObjectsWithoutIdPropertyEnum.ClientBase:
                return (value as ClientBase).icId;

            case ObjectsWithoutIdPropertyEnum.ContractClient:
                return (value as ContractClient).icId;

            case ObjectsWithoutIdPropertyEnum.ContractDetails:
                return (value as ContractDetails).id || '';

            case ObjectsWithoutIdPropertyEnum.ContractFull:
                return (value as ContractFull).id || '';

            case ObjectsWithoutIdPropertyEnum.LoggedUser:
                return (value as LoggedUser).id;

            case ObjectsWithoutIdPropertyEnum.Package:
                return (value as Package).natureId?.toString() || '';

            case ObjectsWithoutIdPropertyEnum.Relationship:
                return (value as Relationship).bdrId || '';

            case ObjectsWithoutIdPropertyEnum.SubscriptionSet:
                return (value as SubscriptionSet).operationalContactId;

            case ObjectsWithoutIdPropertyEnum.ContactAccessesResponse:
                return (value as ContactAccessesResponse)?.contactIcId;

            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const exhaustiveCheck: never = type;
        }
        return '';
    }
}
