import { generatePath } from 'react-router-dom';
import { SearchContractRequest } from 'services/ApiService/ResearchContract/ResearchContractApiClient';
import { TFunction } from 'i18next';

export enum CompliancePage {
    ManualDisclosures = 'manual-disclosures',
    PredefinedDisclosures = 'predefined-disclosures',
}

export const RoutePaths = {
    Errors: {
        NotAuthorized: {
            route: '/error/not-authorized',
            url: () => RoutePaths.Errors.NotAuthorized.route,
        },
        NotAuthorizedContactUs: {
            route: '/error/not-authorized/contact-us',
            url: () => RoutePaths.Errors.NotAuthorizedContactUs.route,
        },
        NotFound: {
            route: '*',
            url: () => '/error/not-found',
        },
    },
    Contracts: {
        title: (t: TFunction<'translation', undefined>) => t('menu:Titles.Contracts'),
        AllContracts: {
            route: '/',
            url: (filters?: Partial<SearchContractRequest>) => RoutePaths.Contracts.AllContracts.route + getQueryParameters({
                skip: filters?.skip,
                search: filters?.searchTerms,
                status: filters?.statuses,
                contractType: filters?.contractTypes,
                clientIcIds: filters?.clientIcIds,
                contactIcIds: filters?.contactIcIds,
            }),
            title: (t: TFunction<'translation', undefined>) => t('menu:Titles.All contracts'),
            Edit: {
                route: '/:contractId/edit',
                url: (contractId: string) => generatePath(RoutePaths.Contracts.AllContracts.Edit.route, { contractId: `${contractId}` }),
                title: (t: TFunction<'translation', undefined>) => t('menu:Titles.Edit contract'),
            },
            View: {
                route: '/:contractId/view',
                url: (contractId: string) => generatePath(RoutePaths.Contracts.AllContracts.View.route, { contractId: `${contractId}` }),
                title: (t: TFunction<'translation', undefined>) => t('menu:Titles.View contract'),
            },
            Create: {
                route: '/create',
                title: (t: TFunction<'translation', undefined>) => t('menu:Titles.Create contract'),
            },
            Duplicate: {
                route: '/:contractId/duplicate',
                url: (contractId: string) => generatePath(RoutePaths.Contracts.AllContracts.Duplicate.route, { contractId: `${contractId}` }),
            },
        },
    },
} as const;

export type RouteParams = {
    Errors: {
        NotAuthorized: ExtractRouteParams<typeof RoutePaths.Errors.NotAuthorized.route, string>,
        NotFound: ExtractRouteParams<typeof RoutePaths.Errors.NotFound.route, string>,
    },
    Contracts: {
        AllContracts: ExtractRouteParams<typeof RoutePaths.Contracts.AllContracts.route, string>,
        Edit: ExtractRouteParams<typeof RoutePaths.Contracts.AllContracts.Edit.route, string>,
        Create: ExtractRouteParams<typeof RoutePaths.Contracts.AllContracts.Create.route, string>,
        View: ExtractRouteParams<typeof RoutePaths.Contracts.AllContracts.View.route, string>,
        Duplicate: ExtractRouteParams<typeof RoutePaths.Contracts.AllContracts.Duplicate.route, string>,
    },
};

const getQueryParameters = (parameters: Record<string, string | number | boolean | undefined | Date | (string | number | boolean | undefined | Date)[]>) => {
    const search = new URLSearchParams(
        Object.entries(parameters)
            .filter(entry => entry[1])
            .map(([key, value]) => ([key, value?.toString() || ''])),
    ).toString();
    return search ? `?${search}` : '';
};


type ExtractRouteOptionalParam<T extends string, U = string | number | boolean> = T extends `${infer Param}?`
    ? { [_ in Param]?: U }
    : T extends `${infer Param}*`
    ? { [_ in Param]?: U }
    : T extends `${infer Param}+`
    ? { [_ in Param]: U }
    : { [_ in T]: U };

/* eslint-disable */
type ExtractRouteParams<T extends string, U = string | number | boolean> = string extends T
    ? { [_ in string]?: U }
    : T extends `${infer _}:${infer ParamWithOptionalRegExp}/${infer Rest}`
    ? ParamWithOptionalRegExp extends `${infer Param}(${infer _})`
    ? ExtractRouteOptionalParam<Param, U> & ExtractRouteParams<Rest, U>
    : ExtractRouteOptionalParam<ParamWithOptionalRegExp, U> & ExtractRouteParams<Rest, U>
    : T extends `${infer _}:${infer ParamWithOptionalRegExp}`
    ? ParamWithOptionalRegExp extends `${infer Param}(${infer _})`
    ? ExtractRouteOptionalParam<Param, U>
    : ExtractRouteOptionalParam<ParamWithOptionalRegExp, U>
    : unknown;
